import React from "react";
import PropTypes from "prop-types";

const Button = ({ label, link, size }) => (
  <a
    className={`button flex items-center justify-center text-center rounded-md bg-gradient-to-tl from-brand-500 via-brand-900 to-brand-500 text-yellow font-semibold uppercase border-2 border-white  ${
      size === "lg"
        ? "px-4 py-3 text-body-xs sm:text-body-lg"
        : "px-4 py-2.5 text-body-sm"
    }`}
    href={link}
  >
    {label}
  </a>
);

Button.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default Button;
